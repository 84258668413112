import { useTranslation } from 'react-i18next';
import './Tours.scss';
import TourItem from './components/TourItem';
import Slideshow from '../../components/Slideshow';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Button } from '@mui/material';

const toursList = [
  {
    title: 'apostles',
    description: 'apostlesDesc',
    className: 'tours__img1',
    link: '/tours/apostles',
  },
  { title: 'north', description: 'northDesc', className: 'tours__img2', link: '/tours/north' },
  { title: 'south', description: 'southDesc', className: 'tours__img3', link: '/tours/south' },
];

const toursList2 = [
  {
    title: 'georgiaTour',
    description: 'georgiaDesc',
    className: 'tours__img4',
    link: '/tours/georgia',
  },
  {
    title: 'shortTour',
    description: 'shortDesc',
    className: 'tours__img5',
    link: '/tours/short',
  },
];

const slideData = [
  { id: 'img1', img: 'https://i.ibb.co/4JyNbkt/5.jpg' },
  { id: 'img2', img: 'https://i.ibb.co/kKG99h1/2.jpg' },
  { id: 'img3', img: 'https://i.ibb.co/DWPypfr/3.jpg' },
];

const Tours = () => {
  const { t } = useTranslation();

  return (
    <>
      <Slideshow data={slideData} />
      <div className="tours">
        <div className="tours__header-title">{t('toursTitle')}</div>
        <div className="tours__wrapper">
          <div className="tours__tours">
            {toursList.map((item) => (
              <TourItem
                title={item.title}
                description={item.description}
                className={item.className}
                link={item.link}
              />
            ))}
          </div>
          <div className="tours__tours2">
            {toursList2.map((item) => (
              <TourItem
                title={item.title}
                description={item.description}
                className={item.className}
                link={item.link}
              />
            ))}
          </div>
        </div>
        <div className="tours__footer-title">{t('footerTitle')}</div>
        <div className="tours__general-wrapper">
          <div className="tours__general-block">
            <SettingsSuggestIcon className="tours__icon" />
            <div className="tours__footer-desc">{t('footerText1')}</div>
            <div className="tours__footer-desc">{t('footerText2')}</div>
            <div className="tours__footer-desc">{t('footerText3')}</div>
            <a className="footer__label" href={'mailto:dreamriders.armenia@gmail.com'}>
              <Button
                style={{
                  backgroundColor: '#283033',
                }}
                variant="contained">
                {t('contactUs')}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tours;
