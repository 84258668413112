import { useTranslation } from 'react-i18next';
import Slideshow from '../../components/Slideshow';
import YouTube from 'react-youtube';
import './Tour66.scss';
import { Button } from '@mui/material';
import { useRef } from 'react';

const slideData = [
  { id: 'img1', img: 'https://i.ibb.co/rdkrsQd/1.jpg' },
  { id: 'img2', img: 'https://i.ibb.co/M7zT3QK/2.jpg' },
  { id: 'img3', img: 'https://i.ibb.co/7NLhWH1/3.jpg' },
];
const Tour66 = () => {
  const { t } = useTranslation();
  const playerRef = useRef(null);

  const opts = {
    height: '390',
    width: '390',
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  };

  const mobileOpts = {
    height: '300',
    width: '300',
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  };

  const onVideoEnd = () => {
    // Access the player using the ref
    const player = playerRef.current;

    // Check if the player is available
    if (player) {
      // Play the video again when it ends
      player.updateVideo();
    }
  };

  return (
    <div>
      <Slideshow data={slideData} />
      <div className="tour66">
        <div className="tour66__wrapper">
          <div className="tour66__video-wrapper">
            <YouTube videoId="thhVkOb0pjQ" opts={opts} onEnd={onVideoEnd} ref={playerRef} />
          </div>
          <div className="tour66__mobile-wrapper">
            <YouTube videoId="thhVkOb0pjQ" opts={mobileOpts} />
          </div>
          <div className="tour66__text-wrapper">
            <div className="tour66__text">{t('tour66Text')}</div>
            <a className="tour66__button" href={'mailto:dreamriders.armenia@gmail.com'}>
              <Button
                style={{
                  backgroundColor: '#283033',
                }}
                variant="contained">
                {t('contactUs')}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tour66;
