import { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { Us, Am, Ru } from 'react-flags-select';

import './Header.scss';

export const tabs = [
  {
    path: '/',
    name: 'home',
  },
  {
    path: '/tours',
    name: 'tours',
  },
  {
    path: '/service',
    name: 'service',
  },
  {
    path: '/rent',
    name: 'rent',
  },
  {
    path: '/tour66',
    name: 'tour66',
  },
];

const Header = () => {
  const { i18n, t } = useTranslation();
  const boxRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(undefined);
  const languageFromUrl = searchParams.get('lang');

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;

      if (scrolled < 200) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault();
    };

    // Disable scroll
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('scroll', disableScroll);
    }

    return () => {
      // Clean up: enable scroll and remove event listener
      document.body.style.overflow = 'auto';
      document.removeEventListener('scroll', disableScroll);
    };
  }, [isOpen]);

  const closeSideBar = () => {
    setIsOpen(false);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
    if (language === 'en') {
      searchParams.delete('lang');
      setSearchParams(searchParams);
      closeSideBar();
      return;
    }
    setSearchParams((prev) => ({
      ...prev,
      lang: language,
    }));
    closeSideBar();
  };
  useEffect(() => {
    if (languageFromUrl) {
      changeLanguage(languageFromUrl);
    }
  }, []);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={boxRef} className={`header header__home`}>
      <Link to="/">
        <div className="header__logo" />
      </Link>
      <div className="header__menu">
        {isOpen && (
          <Menu isOpen={isOpen} width={'250px'} onOpen={handleIsOpen} onClose={closeSideBar} right>
            <div className="header__languages">
              <div className="header__link-wrapper">
                <div onClick={() => changeLanguage('en')}>
                  <Us />
                </div>
              </div>
              <div className="header__link-wrapper">
                <div onClick={() => changeLanguage('ru')}>
                  <Ru />
                </div>
              </div>
              <div className="header__link-wrapper">
                <div onClick={() => changeLanguage('am')}>
                  <Am />
                </div>
              </div>
            </div>
            {tabs.map(({ path, name }) => (
              <NavLink key={path} end to={path} onClick={closeSideBar} className="header__nav-item">
                {t([name])}
              </NavLink>
            ))}
          </Menu>
        )}
        <div className="header__links">
          {tabs.map(({ path, name }) => (
            <NavLink
              key={path}
              end
              to={path}
              className={({ isActive }) =>
                isActive ? 'header__nav-item--active' : 'header__nav-item'
              }>
              {t([name])}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="header__icons">
        <a
          className="footer__label"
          href="https://www.facebook.com/Dreamriders.Armenia"
          target="blank">
          <div className="header__icon header__icon1" />
        </a>
        <a className="footer__label" href="https://wa.me/37477093030" target="blank">
          <div className="header__icon header__icon2" />
        </a>
        <a className="footer__label" href="https://t.me/Dreamriders_Armenia" target="blank">
          <div className="header__icon header__icon3" />
        </a>
        <a href="https://www.instagram.com/dreamriders_armenia/" target="blank">
          <div className="header__icon header__icon4" />
        </a>
      </div>
      <div className="header__languages header__left-languages">
        <div>
          <div onClick={() => changeLanguage('en')}>
            <Us className="header__flag" />
          </div>
        </div>
        <div>
          <div onClick={() => changeLanguage('ru')}>
            <Ru className="header__flag" />
          </div>
        </div>
        <div>
          <div onClick={() => changeLanguage('am')}>
            <Am className="header__flag" />
          </div>
        </div>
      </div>
      <div className="header__burger">
        <MenuIcon onClick={() => setIsOpen(true)} />
      </div>
      <div className={`sticky ${isVisible ? 'visible' : 'hidden'}`}>
        <div className="header__sticky-wrapper">
          <Link to="/">
            <div className="header__logo" />
          </Link>
          <div className="header__links">
            {tabs.map(({ path, name }) => (
              <NavLink
                key={path}
                end
                to={path}
                className={({ isActive }) =>
                  isActive ? 'header__nav-item--active' : 'header__nav-item'
                }>
                {t([name])}
              </NavLink>
            ))}
          </div>
          <div className="header__icons header__icons-sticky">
            <a
              className="footer__label"
              href="https://www.facebook.com/Dreamriders.Armenia"
              target="blank">
              <div className="header__icon header__icon1" />
            </a>
            <a className="footer__label" href="https://wa.me/37477093030" target="blank">
              <div className="header__icon header__icon2" />
            </a>
            <a className="footer__label" href="https://t.me/Dreamriders_Armenia" target="blank">
              <div className="header__icon header__icon3" />
            </a>
            <a href="https://www.instagram.com/dreamriders_armenia/" target="blank">
              <div className="header__icon header__icon4" />
            </a>
          </div>
          <div className="header__languages header__sticky-languages">
            <div>
              <div onClick={() => changeLanguage('en')}>
                <Us className="header__flag" />
              </div>
            </div>
            <div>
              <div onClick={() => changeLanguage('ru')}>
                <Ru className="header__flag" />
              </div>
            </div>
            <div>
              <div onClick={() => changeLanguage('am')}>
                <Am className="header__flag" />
              </div>
            </div>
          </div>
          <div className="header__burger">
            <MenuIcon onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
