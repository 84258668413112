import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './Slideshow.scss';

const Slideshow = ({ data }) => {
  const { t } = useTranslation();

  const firstItem = data.find((item) => item.id === 'img1');

  const [images, setImages] = useState([firstItem.img]);
  const [currentIndex, setCurrentIndex] = useState(1);
  let intervalId;

  const loadImage = async (url) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const blob = await response.blob();
        setImages((prevImages) => [...prevImages, URL.createObjectURL(blob)]);
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  useEffect(() => {
    const downloadImagesWithInterval = () => {
      intervalId = setInterval(() => {
        if (currentIndex < data.length) {
          loadImage(data[currentIndex].img);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(intervalId); // Stop interval when all images are downloaded
        }
      }, 1000); // Change interval time as needed (in milliseconds)
    };

    downloadImagesWithInterval();

    return () => clearInterval(intervalId); // Cleanup function to clear interval on component unmount
  }, [data, currentIndex]);

  return (
    <div className="slideshow__wrapper">
      <div className="slideshow__text">
        {t('dreamRiders')} <span className="slideshow__text2">{t('slideLabel')}</span>
      </div>
      <Fade duration={5000} arrows={false} infinite>
        {images.map((image, index) => (
          <div key={index} className="slideshow__each-slide">
            <div>
              <img
                src={image}
                loading="lazy"
                alt={`Slide ${index}`}
                className="progressive-image"
              />
            </div>
          </div>
        ))}
      </Fade>
    </div>
  );
};

export default Slideshow;
