import { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import 'react-image-gallery/styles/css/image-gallery.css';
import './Gallery.scss';

const Gallery = ({ images }) => {
  const [loadingStates, setLoadingStates] = useState(images.map(() => true));

  const handleImageLoad = (index) => {
    const newLoadingStates = [...loadingStates];
    newLoadingStates[index] = false;
    setLoadingStates(newLoadingStates);
  };

  useEffect(() => {
    const loadImage = (url, index) => {
      const img = new Image();
      img.onload = () => {
        setLoadingStates((prevLoadingStates) => {
          const newLoadingStates = [...prevLoadingStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
      };
      img.src = url;
    };

    loadImage(images[0].original, 0);
    loadImage(images[1].original, 1);
    loadImage(images[images.length - 1].original, images.length - 1);
  }, []);

  const renderCustomSlide = (item) => {
    const { original, description } = item;
    const index = images.findIndex((img) => img.original === original);
    const isLoading = loadingStates[index];

    return (
      <div className="gallery__wrapper">
        {isLoading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : null}
        <img
          src={original}
          alt={description}
          className="gallery__gallery-image"
          onLoad={() => handleImageLoad(index)}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      </div>
    );
  };

  return (
    <div className="gallery">
      <ImageGallery
        thumbnailLoading={'lazy'}
        lazyLoad={true}
        showFullscreenButton={false}
        showPlayButton={false}
        items={images}
        renderItem={renderCustomSlide}
      />
    </div>
  );
};

export default Gallery;
