import LocationOnIcon from '@mui/icons-material/LocationOn';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import logo from '../../../../pictures/logo_black.png';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import './Footer.scss';
import { tabs } from '../../../../components/Header';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer__logo-block">
        <div className="footer__logo-wrapper">
          <LazyLoadImage src={logo} alt="logo" className="footer__logo" />
          <div>
            {'<'} SINCE 2012 {'>'}
          </div>
        </div>
      </div>
      <div className="footer__navigation">
        <div className="footer__title">
          <h4>{t('navigation')}</h4>
        </div>
        <div className="footer__nav-wrapper">
          {tabs.map(({ path, name }) => (
            <NavLink key={path} end to={path} className={'footer__nav-item'}>
              {t([name])}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="footer__about">
        <div className="footer__title">
          <h4>{t('contacts')}</h4>
        </div>
        <div className="footer__address">
          <LocationOnIcon />
          <span className="footer__label">{t('address')}</span>
        </div>
        <div className="footer__address">
          <CallIcon />
          <a className="footer__label" href={'tel:+374-77-093030'}>
            +374-77-093030
          </a>
        </div>
        <div className="footer__address">
          <EmailIcon />
          <a className="footer__label" href={'mailto:dreamriders.armenia@gmail.com'}>
            dreamriders.armenia@gmail.com
          </a>
        </div>
      </div>
      <div className="footer__block">
        <div className="footer__title">
          <h4>{t('social')}</h4>
        </div>
        <div className="footer__social-wrapper">
          <a href="https://www.facebook.com/Dreamriders.Armenia" target="blank">
            <div className="footer__icon footer__icon1" />
          </a>
          <a href="https://wa.me/37477093030" target="blank">
            <div className="footer__icon footer__icon2" />
          </a>
          <a href="https://t.me/Dreamriders_Armenia" target="blank">
            <div className="footer__icon footer__icon3" />
          </a>
          <a href="https://www.instagram.com/dreamriders_armenia/" target="blank">
            <div className="footer__icon footer__icon4" />
          </a>
          <a
            href="https://www.tripadvisor.com/Attraction_Review-g293932-d4100139-Reviews-Dreamriders-Yerevan.html"
            target="blank">
            <div className="footer__icon footer__icon5" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
