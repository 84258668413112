import React, { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './Home.scss';

const Home = () => {
  const picturesData = [
    {
      id: 'img1',
      img: 'https://i.ibb.co/XFQ4vwQ/1.jpg',
    },
    {
      id: 'img2',
      img: 'https://i.ibb.co/6NVhqp0/2.jpg',
    },
    {
      id: 'img3',
      img: 'https://i.ibb.co/b3csTCC/3.jpg',
    },
    {
      id: 'img4',
      img: 'https://i.ibb.co/kHfq6Zq/4.jpg',
    },
    {
      id: 'img5',
      img: 'https://i.ibb.co/4JyNbkt/5.jpg',
    },
    {
      id: 'img6',
      img: 'https://i.ibb.co/7rKhRq2/6.jpg',
    },
    {
      id: 'img7',
      img: 'https://i.ibb.co/FqG1hkr/7.jpg',
    },
  ];

  const LazySlideshow = React.lazy(() => import('../../components/Slideshow'));
  const LazyBasicContent = React.lazy(() => import('./components/BasicContent'));
  const LazyCarouselSlide = React.lazy(() => import('./components/CarouselSlide'));
  const LazyLocation = React.lazy(() => import('./components/Location'));

  return (
    <div className="home">
      <Suspense
        fallback={
          <div className="home__wrapper">
            <CircularProgress />
          </div>
        }>
        <LazySlideshow data={picturesData} />
        <LazyBasicContent />
        <LazyLocation />
        <LazyCarouselSlide />
      </Suspense>
    </div>
  );
};

export default Home;
