const ToursData = {
  apostles: {
    title: 'apostles',
    description: 'apostlesDesc',
    length: 'apostlesLength',
    priceIncludes: 'priceIncludes',
    price: 2450,
    map: 'https://i.ibb.co/Jkn04C3/Apostles.jpg',
    days: [
      'apostlesDay1',
      'apostlesDay2',
      'apostlesDay3',
      'apostlesDay4',
      'apostlesDay5',
      'apostlesDay6',
      'apostlesDay7',
      'apostlesDay8',
      'apostlesDay9',
      'apostlesDay10',
      'apostlesDay11',
    ],
    images: [
      {
        original: 'https://i.ibb.co/zb2fcth/1.jpg',
        thumbnail: 'https://i.ibb.co/PY3kM7g/1.jpg',
      },
      {
        original: 'https://i.ibb.co/JqC2ZTm/2.jpg',
        thumbnail: 'https://i.ibb.co/fdwGFnH/2.jpg',
      },
      {
        original: 'https://i.ibb.co/NSQ4NWj/3.jpg',
        thumbnail: 'https://i.ibb.co/8bRLQQz/3.jpg',
      },
      {
        original: 'https://i.ibb.co/m5MrzTB/4.jpg',
        thumbnail: 'https://i.ibb.co/xHRY1bb/4.jpg',
      },
      {
        original: 'https://i.ibb.co/tq6NBwm/2.jpg',
        thumbnail: 'https://i.ibb.co/vsTZ08Y/5.jpg',
      },
      {
        original: 'https://i.ibb.co/Rjr5zXc/6.jpg',
        thumbnail: 'https://i.ibb.co/6RmysDD/6.jpg',
      },
      {
        original: 'https://i.ibb.co/FgXNGB9/22.jpg',
        thumbnail: 'https://i.ibb.co/1qpW2Rd/7.jpg',
      },
      {
        original: 'https://i.ibb.co/mqn3qH6/8.jpg',
        thumbnail: 'https://i.ibb.co/z76SncW/8.jpg',
      },
      {
        original: 'https://i.ibb.co/h8qFRX2/9.jpg',
        thumbnail: 'https://i.ibb.co/ckzGPJV/9.jpg',
      },
      {
        original: 'https://i.ibb.co/59tmNpQ/5.jpg',
        thumbnail: 'https://i.ibb.co/T0DtW8x/10.jpg',
      },
      {
        original: 'https://i.ibb.co/F8CX71W/11.jpg',
        thumbnail: 'https://i.ibb.co/swvBWkK/11.jpg',
      },
      {
        original: 'https://i.ibb.co/zbSzLXr/12.jpg',
        thumbnail: 'https://i.ibb.co/0mdtnp4/12.jpg',
      },
      {
        original: 'https://i.ibb.co/vjh4wmy/6.jpg',
        thumbnail: 'https://i.ibb.co/nBCwvqC/13.jpg',
      },
      {
        original: 'https://i.ibb.co/QD7pNLQ/14.jpg',
        thumbnail: 'https://i.ibb.co/594mybg/14.jpg',
      },
      {
        original: 'https://i.ibb.co/3yMpVdv/15.jpg',
        thumbnail: 'https://i.ibb.co/5x05QYm/15.jpg',
      },
    ],
  },
  north: {
    title: 'north',
    description: 'northDesc',
    price: 1800,
    priceIncludes: 'priceIncludes',
    map: 'https://i.ibb.co/ypcYGWn/North.jpg',
    days: [
      'northDay1',
      'northDay2',
      'northDay3',
      'northDay4',
      'northDay5',
      'northDay6',
      'northDay7',
    ],
    images: [
      {
        original: 'https://i.ibb.co/Wxq2GFp/1.jpg',
        thumbnail: 'https://i.ibb.co/gTwVMZn/1.jpg',
      },
      {
        original: 'https://i.ibb.co/3M97wGw/2.jpg',
        thumbnail: 'https://i.ibb.co/dfsxyhn/2.jpg',
      },
      {
        original: 'https://i.ibb.co/f26TnjY/3.jpg',
        thumbnail: 'https://i.ibb.co/HhxTfj5/3.jpg',
      },
      {
        original: 'https://i.ibb.co/1q9GwpV/4.jpg',
        thumbnail: 'https://i.ibb.co/j3x5vxJ/4.jpg',
      },
      {
        original: 'https://i.ibb.co/Y7w5Kb6/5.jpg',
        thumbnail: 'https://i.ibb.co/YhnRkm5/5.jpg',
      },
      {
        original: 'https://i.ibb.co/vjh4wmy/6.jpg',
        thumbnail: 'https://i.ibb.co/YTr0cCB/6.jpg',
      },
      {
        original: 'https://i.ibb.co/B2Bs440/7.jpg',
        thumbnail: 'https://i.ibb.co/dcLT6P8/7.jpg',
      },
      {
        original: 'https://i.ibb.co/kQcKrh5/8.jpg',
        thumbnail: 'https://i.ibb.co/51td85V/8.jpg',
      },
      {
        original: 'https://i.ibb.co/hFxzpNd/9.jpg',
        thumbnail: 'https://i.ibb.co/6wYZGvD/9.jpg',
      },
      {
        original: 'https://i.ibb.co/3kRzCFV/10.jpg',
        thumbnail: 'https://i.ibb.co/10q9gn5/10.jpg',
      },
      {
        original: 'https://i.ibb.co/PCqrvH4/11.jpg',
        thumbnail: 'https://i.ibb.co/hYv0Ydp/11.jpg',
      },
      {
        original: 'https://i.ibb.co/DDVq2bn/12.jpg',
        thumbnail: 'https://i.ibb.co/LrT6ss0/12.jpg',
      },
    ],
  },
  south: {
    title: 'south',
    description: 'southDesc',
    price: 1800,
    priceIncludes: 'priceIncludes',
    map: 'https://i.ibb.co/GTdrKnF/South.jpg',
    days: [
      'southDay1',
      'southDay2',
      'southDay3',
      'southDay4',
      'southDay5',
      'southDay6',
      'southDay7',
    ],
    images: [
      {
        original: 'https://i.ibb.co/NTRW5nf/1.jpg',
        thumbnail: 'https://i.ibb.co/7tjzbHB/1.jpg',
      },
      {
        original: 'https://i.ibb.co/tMXdFmT/2.jpg',
        thumbnail: 'https://i.ibb.co/xgzwFRK/2.jpg',
      },
      {
        original: 'https://i.ibb.co/JQZgZjF/3.jpg',
        thumbnail: 'https://i.ibb.co/S3JdBGv/3.jpg',
      },
      {
        original: 'https://i.ibb.co/F8qW7M9/5.jpg',
        thumbnail: 'https://i.ibb.co/3h7sfWR/5.jpg',
      },
      {
        original: 'https://i.ibb.co/7KtC8dy/6.jpg',
        thumbnail: 'https://i.ibb.co/jfjPx8z/6.jpg',
      },
      {
        original: 'https://i.ibb.co/MD25bm8/7.jpg',
        thumbnail: 'https://i.ibb.co/Kwb5Hp7/7.jpg',
      },
      {
        original: 'https://i.ibb.co/4W8StVD/8.jpg',
        thumbnail: 'https://i.ibb.co/d4zHLmL/8.jpg',
      },
      {
        original: 'https://i.ibb.co/sm7hwXj/9.jpg',
        thumbnail: 'https://i.ibb.co/6r4XL50/9.jpg',
      },
      {
        original: 'https://i.ibb.co/yhk1Gbg/10.jpg',
        thumbnail: 'https://i.ibb.co/N9rkZWG/10.jpg',
      },
      {
        original: 'https://i.ibb.co/VNtt8zc/11.jpg',
        thumbnail: 'https://i.ibb.co/YDvDRk3/11.jpg',
      },
      {
        original: 'https://i.ibb.co/JHZGPPs/12.jpg',
        thumbnail: 'https://i.ibb.co/7GFMRGV/12.jpg',
      },
    ],
  },
  georgia: {
    title: 'georgia',
    description: 'georgiaDesc',
    price: 4050,
    pricePerpass: 3250,
    map: 'https://i.ibb.co/0Cq5x7c/Armenia-Gorgia.jpg',
    escortPrice: 2100,
    note: 'georgiaNote',
    priceIncludes: 'priceIncludesAcc',
    days: [
      'georgiaDay1',
      'georgiaDay2',
      'georgiaDay3',
      'georgiaDay4',
      'georgiaDay5',
      'georgiaDay6',
      'georgiaDay7',
      'georgiaDay8',
      'georgiaDay9',
      'georgiaDay10',
      'georgiaDay11',
      'georgiaDay12',
      'georgiaDay13',
      'georgiaDay14',
      'georgiaDay15',
      'georgiaDay16',
    ],
    images: [
      {
        original: 'https://i.ibb.co/pZ4Jr51/1.jpg',
        thumbnail: 'https://i.ibb.co/KwVwKvv/1.jpg',
      },
      {
        original: 'https://i.ibb.co/j4S0xj7/2.jpg',
        thumbnail: 'https://i.ibb.co/v103njN/2.jpg',
      },
      {
        original: 'https://i.ibb.co/DCfmzdx/3.jpg',
        thumbnail: 'https://i.ibb.co/9qmXDTg/3.jpg',
      },
      {
        original: 'https://i.ibb.co/gVtCTq7/4.jpg',
        thumbnail: 'https://i.ibb.co/z2N8ymL/4.jpg',
      },
      {
        original: 'https://i.ibb.co/TMCJXK8/5.jpg',
        thumbnail: 'https://i.ibb.co/vwgkY6g/5.jpg',
      },
      {
        original: 'https://i.ibb.co/s6kf08Q/6.jpg',
        thumbnail: 'https://i.ibb.co/VmgV7fz/6.jpg',
      },
      {
        original: 'https://i.ibb.co/JRG5DtM/7.jpg',
        thumbnail: 'https://i.ibb.co/wScsMBh/7.jpg',
      },
      {
        original: 'https://i.ibb.co/GFXbGh7/8.jpg',
        thumbnail: 'https://i.ibb.co/Gnynhmn/8.jpg',
      },
      {
        original: 'https://i.ibb.co/Qv38Cvc/9.jpg',
        thumbnail: 'https://i.ibb.co/6s4GL70/9.jpg',
      },
      {
        original: 'https://i.ibb.co/KFMGJX3/10.jpg',
        thumbnail: 'https://i.ibb.co/h8vKZkW/10.jpg',
      },
      {
        original: 'https://i.ibb.co/LpQPQK7/11.jpg',
        thumbnail: 'https://i.ibb.co/5Yr33jV/11.jpg',
      },
      {
        original: 'https://i.ibb.co/Gd8kFty/12.jpg',
        thumbnail: 'https://i.ibb.co/Rpz9wV7/12.jpg',
      },
      {
        original: 'https://i.ibb.co/dP8Bw0Y/13.jpg',
        thumbnail: 'https://i.ibb.co/j3XhyN0/13.jpg',
      },
      {
        original: 'https://i.ibb.co/1Jd6x5K/14.jpg',
        thumbnail: 'https://i.ibb.co/Mk0By5y/14.jpg',
      },
      {
        original: 'https://i.ibb.co/5WgKjMG/15.jpg',
        thumbnail: 'https://i.ibb.co/4twm9qb/15.jpg',
      },
      {
        original: 'https://i.ibb.co/QY3n0ZM/16.jpg',
        thumbnail: 'https://i.ibb.co/T41TGnQ/16.jpg',
      },
      {
        original: 'https://i.ibb.co/vYdV3T2/17.jpg',
        thumbnail: 'https://i.ibb.co/x71JTSW/17.jpg',
      },
      {
        original: 'https://i.ibb.co/tmwLx8B/18.jpg',
        thumbnail: 'https://i.ibb.co/1nqxmTb/18.jpg',
      },
      {
        original: 'https://i.ibb.co/58N6zKp/19.jpg',
        thumbnail: 'https://i.ibb.co/h9RPxnM/19.jpg',
      },
      {
        original: 'https://i.ibb.co/yhk1Gbg/10.jpg',
        thumbnail: 'https://i.ibb.co/qL8TpX9/20.jpg',
      },
      {
        original: 'https://i.ibb.co/t36gQjh/21.jpg',
        thumbnail: 'https://i.ibb.co/N1b46vX/21.jpg',
      },
      {
        original: 'https://i.ibb.co/FgXNGB9/22.jpg',
        thumbnail: 'https://i.ibb.co/QNwpmbT/22.jpg',
      },
      {
        original: 'https://i.ibb.co/tpN0ZSC/23.jpg',
        thumbnail: 'https://i.ibb.co/MprhwSP/23.jpg',
      },
      {
        original: 'https://i.ibb.co/yBhhnn7/24.jpg',
        thumbnail: 'https://i.ibb.co/r3j0Bd5/24.jpg',
      },
      {
        original: 'https://i.ibb.co/BggMtVm/25.jpg',
        thumbnail: 'https://i.ibb.co/tQCFwhC/25.jpg',
      },
      {
        original: 'https://i.ibb.co/cypN2V0/26.jpg',
        thumbnail: 'https://i.ibb.co/cbS0dXV/26.jpg',
      },
      {
        original: 'https://i.ibb.co/mhrM7rD/27.jpg',
        thumbnail: 'https://i.ibb.co/QdFwMVS/27.jpg',
      },
      {
        original: 'https://i.ibb.co/p4Vyvbh/28.jpg',
        thumbnail: 'https://i.ibb.co/nMpTH53/28.jpg',
      },
      {
        original: 'https://i.ibb.co/pxYjrsB/29.jpg',
        thumbnail: 'https://i.ibb.co/YLvrSk4/29.jpg',
      },
      {
        original: 'https://i.ibb.co/fnG4ktf/30.jpg',
        thumbnail: 'https://i.ibb.co/mFt4H5x/30.jpg',
      },
      {
        original: 'https://i.ibb.co/QCXbmcC/31.jpg',
        thumbnail: 'https://i.ibb.co/HX0xBfw/31.jpg',
      },
      {
        original: 'https://i.ibb.co/YjxqhQd/32.jpg',
        thumbnail: 'https://i.ibb.co/108p8Kw/32.jpg',
      },
    ],
  },
  short: {
    title: 'short',
    description: 'shortDesc',
    price: 1070,
    priceIncludes: 'priceIncludes',
    map: 'https://i.ibb.co/9GmQYgq/Short.jpg',
    days: ['shortDay1', 'shortDay2', 'shortDay3', 'shortDay4', 'shortDay5'],
    images: [
      {
        original: 'https://i.ibb.co/Bgh4RWK/1.jpg',
        thumbnail: 'https://i.ibb.co/bbF4Yrk/1.jpg',
      },
      {
        original: 'https://i.ibb.co/tq6NBwm/2.jpg',
        thumbnail: 'https://i.ibb.co/SX4dtHk/2.jpg',
      },
      {
        original: 'https://i.ibb.co/tMXdFmT/2.jpg',
        thumbnail: 'https://i.ibb.co/7XK6jyN/3.jpg',
      },
      {
        original: 'https://i.ibb.co/rH0gW4M/4.jpg',
        thumbnail: 'https://i.ibb.co/kgVCrDy/4.jpg',
      },
      {
        original: 'https://i.ibb.co/59tmNpQ/5.jpg',
        thumbnail: 'https://i.ibb.co/Wyn5SMc/5.jpg',
      },
      {
        original: 'https://i.ibb.co/HNHMXYH/6.jpg',
        thumbnail: 'https://i.ibb.co/Yfx35nS/6.jpg',
      },
      {
        original: 'https://i.ibb.co/4tDLbPX/7.jpg',
        thumbnail: 'https://i.ibb.co/jg0MD5M/7.jpg',
      },
    ],
  },
};

export default ToursData;
