// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      dreamRiders: 'DREAMRIDERS',
      slideLabel: 'MOTORCYCLE TOURS AND RENTAL IN ARMENIA & GEORGIA',
      about:
        'Dreamriders is a company that was founded back in 2012 by travellers for travellers. The roads in Armenia are very well suited for this purpose. An abundance of serpentine routes and dirt roads crosscuts the picturesque mountainous landscape of the country. Our own driving experience, as well as the feedbacks of motorcyclists traveling in our region helped us in organizing high class motorcycle tours in Armenia and Georgia.',
      home: 'Home',
      tours: 'Motorcycle Tours',
      service: 'Service & Storage',
      serviceLabel: 'Service',
      serviceText:
        'Professional approach will be provided, that may include checking, maintaining, or changing all the critical elements and advise on any upgrades or defect detection and fixing, preventive approach to keep your motorcycle running at its best.',
      storage: 'Storage',
      storageText:
        'Our company provides motorcycle storage service. It became a beloved place for travelers and locals to store the bike for the winter time, so they can continue riding with serviced bike in the Spring time. Bikes stay at a warm and secure place with 24/7 live guard.',
      rent: 'Motorcycle Rent',
      contactUs: 'Contact us',
      tour66: 'Tour 66',
      aboutUs: 'About us',
      whatOffer: 'What we offer',
      gallery: 'Gallery',
      more: 'More',
      apostles: 'Armenia. The Routes of the Apostles',
      north: 'North',
      south: 'South',
      toursTitle: 'Motorcycle Tours',
      day: 'Day',
      price: 'Price',
      note: 'Note',
      pricePerRider: 'Price per rider',
      pricePerpass: 'Price per passenger',
      escortPrice: 'Support vehicle price',
      currency: 'EUR',
      priceIncludes: 'The price includes accommodation, food and insurance of motorcycles.',
      priceIncludesAcc:
        'The price includes accommodation, food, non alcoholic drinks, fuel for bikes, guide on motorcycle and insurance of motorcycles.',
      apostlesDesc:
        'Length: 1500 km. 10 days, 9 days of riding. A guide on motorcycle and an support vehicle is provided.',
      northDesc:
        'Length: 900 km. 6 days, 5 days of riding. A guide on motorcycle and an support vehicle is provided.',
      southDesc:
        'Length: 1000 km. 6 days, 5 days of riding. A guide on motorcycle and an support vehicle is provided.',
      georgiaTour: 'Armenia-Georgia',
      shortTour: 'Short',
      shortDesc:
        'Length: 900 km. 4 days, 3 days of riding.A guide on motorcycle and a support vehicle are provided.',

      apostlesDay1:
        'Arrival in the capital of Armenia, Yerevan. Hotel accommodation. City tour. Familiarization with motorcycles in the garage.',
      apostlesDay2:
        '150 km. Arrival at the garage at 8:00. Taking direction towards Geghard Monastery. Stops: Ancient temple in Garni, Geghard Monastery. Then riding uphill to Mount Aragats. Stops: Amberd Fortress, halt at Yezidi nomadic herders, lake Kari Lich, overnight at mountain hotel.',
      apostlesDay3:
        '180 km. (70 km dirt road). Going north to the biggest waterfall of Armenia "Trchkan" at Shirakamut Village, then by a dirt road to Stepanavan. Visiting the fortress "Lori Berd." Overnight in Stepanavan.',
      apostlesDay4:
        '160 km. Going to Odzun Village. A short visit to Mikoyan Brothers Museum. Down to the Debed River gorge. Next: Haghpat Monastery. Overnight in Ijevan.',
      apostlesDay5:
        '150 km. (75 km dirt road).Going by dirt road to Navur Village. Then to the lake Parz Lich. A short break at a cafe near the lake. Staying at Dilijan authentic hotel.',
      apostlesDay6:
        '225 km. (asphalt).Going to the village of Molokans, Semenovka. Next stop: on the picturesque peninsula of Lake Sevan. Trying fish from the lake at a restaurant on the beach. Visit to the medieval cemetery in Noradus Village. Going through the Selim Pass to Yeghegnadzor. On the way, a quick stop at the caravanserai on the Silk Road. Overnight in resort town Jermuk.',
      apostlesDay7:
        '150 km. ( 40 km dirt road ) Going by M2 highway to Shaki Waterfall near Sisian. Next: the old settlement of Khndzoresk. Then going to a place known as "Karahunj” Armenian Stonehenge. Overnight in Goris.',
      apostlesDay8:
        '130 km. (80 km dirt road).Going by highway up to the fork of the road near Shurnukh Village. Taking this dirt road with numerous serpentines running through picturesque mountains. At Shinuayr Village, taking the direction of Tatev Monastery. You can choose to ride on Europe"s longest cable car there and back. After the visit to the monastery going to the city of Kapan through mountain villages.',
      apostlesDay9:
        '240 km. Departing from Kapan to the south through Shikahogh Reserve towards the border with Iran. Making a big loop through the beautiful mountain road (160 km) going back to Kapan and then to Goris.',
      apostlesDay10:
        '270 km. Returning to Yerevan. On the 170th kilometer, turning to the scenic road, which runs along the bottom of the canyon to Noravank Church Complex. Farewell dinner in the evening in Yerevan.',
      apostlesDay11: 'End of the tour, transfer to airport.',

      northDay1:
        'Arrival in the capital of Armenia, Yerevan. Hotel accommodation. City tour. Familiarization with motorcycles in the garage.',
      northDay2:
        '150 km. Arrival at the garage at 8:00. Taking direction towards Geghard Monastery. Stops: Ancient temple in Garni, Geghard Monastery. Then riding uphill to Mount Aragats. Stops: Amberd Fortress, halt at Yezidi nomadic herders, lake Kari Lich, overnight at mountain hotel.',
      northDay3:
        '180 km. (70 km dirt road). Going north to the biggest waterfall of Armenia "Trchkan" at Shirakamut Village, then by a dirt road to Stepanavan. Visiting the fortress "Lori Berd." Overnight in Stepanavan.',
      northDay4:
        '160 km. Going to Odzun Village. A short visit to Mikoyan Brothers Museum. Down to the Debed River gorge. Next: Haghpat Monastery. Overnight in Ijevan.',
      northDay5:
        '150 km. (75 km dirt road).Going by dirt road to Navur Village. Then to the lake Parz Lich. A short break at a cafe near the lake. Staying at Dilijan authentic hotel.',
      northDay6:
        'Going to the village of Molokans, Semenovka. Next stop: on the picturesque peninsula of Lake Sevan. Trying fish from the lake at a restaurant on the beach. Going round the lake and visiting the medieval cemetery in Noradus Village. Back to Yerevan. Farewell dinner in the evening in Yerevan.',
      northDay7: 'End of the tour, transfer to airport.',

      southDay1:
        'Arrival in the capital of Armenia, Yerevan. Hotel accommodation. City tour. Familiarization with motorcycles in the garage.',
      southDay2:
        'Arrival at the garage at 8:00. Taking direction towards the resort town of Jermuk. Stops: lake Sevan, medieval cemetery in Noradus Village, caravanserai on the Silk Road. Overnight in resort town Jermuk.',
      southDay3:
        '130 km. (80 km dirt road). Going by highway up to the fork of the road near Shurnukh Village. Taking this dirt road with numerous serpentines running through picturesque mountains. At Shinuayr Village, taking the direction of Tatev Monastery. You can choose to ride on Europe"s longest cable car there and back. After the visit to the monastery going to the city of Kapan through mountain villages. Overnight in Kapan.',
      southDay4:
        '240 km. Departing from Kapan to the south through Shikahogh Reserve towards the border with Iran. Making a big loop through the beautiful mountain road (160 km) going back to Kapan and then to Goris. Overnight in Goris.',
      southDay5:
        '180km. (60 dirt road) Departing from Goris and going to the prehistoric monument, "Karahunj” Armenian Stonehenge. Next: visit to the abandoned old cave settlement of Khndzoresk on rapid slopes of a gorge. Going to Yeghegnadzor through Herher Reserve. Stopping at the beekeepers to try honey.',
      southDay6:
        '200km. Returning to Yerevan. On the way, turning to the scenic road, which runs along the bottom of the canyon to Noravank Church Complex. Next stop: Khor Virap church at the foot of Mount Ararat. Afterwards going to the village of Garni to see the ancient temple and then the unique Geghard Monastery located not far. Back to Yerevan. Farewell dinner in the evening in Yerevan.',
      southDay7: 'End of the tour, transfer to airport.',

      georgia: 'Armenia-Georgia tour',
      georgiaNote: 'support vehicle is mandatory',
      georgiaDesc: 'Length 2750km. 15 days, 14 days of riding',
      georgiaDay1:
        'Arrival in the capital of Armenia, Yerevan. Hotel accommodation. City tour. Familiarization with motorcycles in the garage. Dinner.',
      georgiaDay2:
        '150 km. Arrival at the garage at 8:00. Taking direction towards Geghard Monastery. Stops: Ancient temple in Garni, Geghard Monastery. Then riding uphill to Mount Aragats. Stops: Amberd Fortress, halt at Yezidi nomadic herders, lake Kari Lich, overnight at mountain hotel.',
      georgiaDay3:
        '170 km. (40km dirt road)Taking direction to Spitak mountain pass, on the way shall stop at “Stone Alphabet”, then move to the Saghmosavank Monastery, which is located next to the most beautiful canyon in Armenia. Moving to the Gyumri which has been known as the main city of crafts. Overnight in Gyumri city.',
      georgiaDay4:
        '225 km. Taking our way to Georgia. After crossing the border we"ll pass through city Akhalkalaki and enter the gorge which will lead us to cave Monastery Vardzia, afterwards we"ll continue our way to castle Rabati in Akhaltsikhe. Dinner and overnight at the Akhaltsikhe bikers guesthouse.',
      georgiaDay5:
        '160 km. (80 km dirt road). Leaving in the morning to the Black sea through Khulo mountain pass, which is a very interesting and sightseeing road. On our way we"ll meet both asphalt and gravel roads. Destination city is Batumi, dinner on the seashore.',
      georgiaDay6:
        '300 km. Starting from the Black Sea our direction will be to the central part of the country, by many mountain passes and beautiful roads we"ll reach the resort town Borjomi. Overnight in Borjomi.',
      georgiaDay7:
        '250 km. We shall start our day with a big move to one of the oldest cities in Georgia, Mtskheta, which is known as a world heritage site for it cultural monuments. Next stop is Ananuri Castle complex, after which we shall take direction to the Mount Kazbegi where at the elevation of 2170m. is located the most famous church of that area, the Gergeti Trinity Church. By the end of the day we"ll reach resort town Gudauri and stay overnight in Stepanzminda.',
      georgiaDay8:
        '130 km. The goal for Day 8 is to feel the attraction of the Capital city Tbilisi. That’s why we shall ride there, park the bikes and explore the city by car and on foot. We shall visit main sites of the city shall have a walk in the old town of Tbilisi, in the evening we shall have dinner in traditional Georgian restaurant with folk music.',
      georgiaDay9:
        '210 km. Crossing the border back to Armenia, and riding by the canyon road we"ll reach Lori region and stop at the 10th century Monastery Complex Haghpat, afterwards go to the town Sanahin where the medieval educational and church complex Sanahin is located. In the same town we"ll find the museum of Mikoyan brothers. Then by the beautiful road in mountain valleys we do a nice loop and get to city Vanadzor. Overnight in Dzoraget.',
      georgiaDay10:
        '200 km. (75 km dirt road) On the 10th day we shall get to city Ijevan and start our mountain trail with the gravel and dirt roads to city Karmir, after we are back on asphalt, we shall move to Lake Parz by the interesting road in gorge then up to the mountain again. Overnight in city Dilijan.',
      georgiaDay11:
        '175 km. (asphalt). Going to the village of Molokans, Semenovka. Next stop: on the picturesque peninsula of Lake Sevan. Trying fish from the lake at a restaurant on the beach. Visit to the medieval cemetery in Noradus Village. Going through the Selim Pass to Yeghegnadzor. On the way, a quick stop at the caravanserai on the SilkRoad. Overnight in Jermuk.',
      georgiaDay12:
        '150 km. Starting the day with beautiful off road (40km) Visiting Jermuk Waterfall. Next: the old settlement of Khndzoresk. On our way we shall stop at a place known as "Karahunj” Armenian Stonehenge. Overnight in Goris.',
      georgiaDay13:
        '130 km. (80 km dirt road). Going by highway up to the fork of the road near Shurnukh Village. Taking this dirt road with numerous serpentines running through picturesque mountains. At Shinuayr Village, taking the direction of Tatev Monastery. You can choose to ride on Europe"s longest cable car there and back. After the visit to the monastery going to the city of Kapan through mountain villages.',
      georgiaDay14:
        '240 km. Departing from Kapan to the south through Shikahogh Reserve towards the border with Iran. Making a big loop through the beautiful mountain road (160 km) going back to Kapan and then to Goris.',
      georgiaDay15:
        '270 km. Returning to Yerevan. On the 170th kilometer, turning to the scenic road, which runs along the bottom of the canyon to Noravank Church Complex. Farewell dinner in the evening in Yerevan.',
      georgiaDay16: 'End of tour, transfer to Airport.',

      short: 'Short',
      shortDay1:
        'Arrival in the capital of Armenia, Yerevan. Hotel accommodation. City tour. Familiarization with motorcycles in the garage.',
      shortDay2:
        '260km. Arrival at the garage at 8:00 am, taking direction to Khor Virap monastery, after short stop moving up to a scenic road, which runs along the bottom of the canyon to Noravank Church Complex. Afterwards departing from Noravank we are going to Tatev Monastery, where the longest rope way is located. Overnight in Goris city.',
      shortDay3:
        '300km. Leaving the Goris city we shall visit the "Karahunj" Stonehenge, after that to Caravanserai on the Silk Road. Cruising along the Sevan Lake we shall visit Sevanavank Church, in the end of the day beautiful road leading to Yenokavan village. Overnight in Yenokavan.',
      shortDay4:
        '240km. Taking direction to lake Parz Lich in the mounts of Dilijan city after short stop in cafe near the lake going to lake Kari Lich on the highest mountain in Armenia mount Aragats, short stop on the road near "Stone Alphabet". Leaving the lake going down to Amberd - a 7th-century fortress. After Amberd beautiful road leading to the Saghmosavank a 13th-century Armenian monastic complex. Farewell dinner in the evening in Yerevan. Overnight in Yerevan.',
      shortDay5: 'End of the tour, transfer to airport.',

      weight: 'Weight',
      type: 'Type',
      engine: 'Engine',
      stroke: 'Stroke',
      volume: 'Volume',
      finalDrive: 'Final drive',
      transmission: 'Transmission',
      power: 'Power',
      cooling: 'Cooling',

      bmw1weight: '244 kg',
      dualType: 'Dual-sport',
      bmw1engine: 'Boxer Twin',
      cardan: 'Cardan',
      speed5: '5 speed',
      airLiquid: 'Air/Liquid',
      bmw2weight: '191 kg',
      engineCylinder1: '1 cylinder',
      chain: 'Chain',
      suzukiWeight: '166 kg',
      airOil: 'Air/Oil',
      hondaWeight: '205 kg',
      vTwinEngine: 'V twin',
      liquid: 'Liquid',
      kawasakiWeight: '192 kg',
      kawasaki2Weight: '189 kg',
      yamahaWeight: '181 kg',
      hp: 'HP',

      terms: 'Terms & Conditions',
      term1: '- 24 hour rent, including tax',
      term2: '- Maximum mileage 250 km/day.',
      term3: '- Security lock',
      term4: '- Insurance of motorbikes',
      term5: '- Optional: Guiding service available',

      requirement1: '- Must be 21 years or older, passport',
      requirement2: "- Motorcycle driver's license ",
      requirement3: '- Damage deposit held in cash 300€',
      requirement4: '- Passenger insurance 25€',

      price1: '- 1-6 days: 80€ per day (starting from)',
      price2: '- 7-14 days: 70€ per day',
      price3: '- 15+ days: 60€ per day',
      price4: '- In case of passenger: +25€ per day',
      price5: '- Support vehicle: 150€ per day',
      price6: '- Damage deposit: 300€',

      footerTitle: 'Custom Tour',
      footerText1:
        'In addition, especially for those who have the desire, but are short of time, we offer customized tours where one can choose the route and duration of the trip.',
      footerText2:
        'The tours take place on dual-purpose motorcycles, accompanied by an experienced guide on a motorcycle and an support technical vehicle.',
      footerText3: 'The best season for motorbike tours is from April 15 to October 15.',
      clickToCome: 'Click to come',
      navigation: 'Navigation',
      contacts: 'Contacts',
      social: 'Social platforms',
      address: 'Armenia Yerevan Davtashen, 3rd District 19/2 Building',
      tour66Text:
        'If you want an unforgettable off road tour for you and your friends- this is the way to go. Our truck can take up to 13 passengers to most beautiful and hidden places of Armenia. For smaller groups or higher distances we provide 4x4 vehicles with professional drivers/guides also.',
    },
  },
  ru: {
    translation: {
      dreamRiders: 'ДРИМРАЙДЕРС',
      slideLabel: 'МОТОТУРЫ И АРЕНДА МОТОЦИКЛОВ В АРМЕНИИ И ГРУЗИИ',
      about:
        'Дримрайдерс компания, основанная еще в 2012 году путешественниками для путешественников. Дороги в Армении очень хорошо подходят для этой цели. Обилие серпантинов и грунтовых дорог пересекает живописный горный ландшафт страны. Собственный опыт вождения, а также отзывы мотоциклистов, путешествующих по нашему региону, помогли нам в организации мото-туров высокого класса по Армении и Грузии.',
      home: 'Главная',
      tours: 'Мото Туры',
      service: 'Сервис и Хранилище',
      serviceLabel: 'Сервис',
      serviceText:
        'Будет предоставлен профессиональный подход, который может включать проверку, обслуживание или замену всех критически важных элементов, а также рекомендации по любым обновлениям или обнаружению и устранению дефектов, а также профилактический подход, позволяющий поддерживать ваш мотоцикл в наилучшем состоянии.',
      storage: 'Хранилище',
      storageText:
        'Наша компания предоставляет услуги хранения мотоциклов. Она стала любимым местом путешественников и местных мотоциклистов для хранения мотоцикла на зимнее время, чтобы они могли продолжить путешествие на обслуженном мотоцикле весной. Мотоциклы хранятся в теплом и безопасном месте под круглосуточной живой охраной.',
      rent: 'Мото Прокат',
      contactUs: 'Cвязаться',
      tour66: 'Тур 66',
      aboutUs: 'О нас',
      gallery: 'Галерея',
      whatOffer: 'Наши услуги',
      more: 'Подробнее',
      apostles: 'Армения. Дорогами Апостолов',
      north: 'Север',
      south: 'Юг',
      toursTitle: 'Мото Туры',
      day: 'День',
      note: 'Внимание',
      pricePerpass: 'Цена для пассажира',
      escortPrice: 'Цена автомобиля технической поддержки',
      priceIncludesAcc:
        'В цену вклячены ночлег, питание, безалкогольные напитки, топливо для мотоциклов, услуги гида и страховка мотоциклов.',
      price: 'Цена',
      currency: 'Евро',
      priceIncludes: 'В цену включены ночлег, питание и страховка мотоциклов.',
      apostlesDesc:
        'Протяженность – 1500 км. 10 дней, 9 дней езды. Предоставляется гид на мотоцикле, машина технической поддержки.',
      northDesc:
        'Протяженность – 900 км. 6 дней, 5 дней езды. Предоставляется гид на мотоцикле, машина технической поддержки.',
      southDesc:
        'Протяженность – 1000 км. 6 дней, 5 дней езды. Предоставляется гид на мотоцикле, машина технической поддержки.',
      georgiaTour: 'Армения-Грузия',
      georgia: 'Армения-Грузия',

      georgiaDay1:
        'Прибытие в столицу Армении город Ереван. Размещение в гостинице. Экскурсия по городу. Ознакомление с мотоциклами в гараже. Обед.',
      georgiaDay2:
        '150 км. Прибытие в гараж в 8:00. Держим направление в сторону монастыря Гегард. Остановки – Античный храм в Гарни, монастырь Гегард. Далее подъем на гору Арагац. Остановки – крепость Амберд, привал у Езидских кочевых пастухов, озеро Кари лич, н。',
      georgiaDay3:
        '170 км (40 км грунтовка). Берем направление к горному перевалу Спитак, на нашем пути мы посетим "Каменный алфавит", а после - монастырь Сагмосаванк, который находится рядом с самым красивым каньоном Армении. Далее едем в город Гюмри, который был известен как главный город ремесел. Ночёвка в городе Гюмри.',
      georgiaDay4:
        '225 км. Направляемся в Грузию. После пересечения границы мы пройдем через город Ахалкалаки и войдем в ущелье, которое приведет нас к пещерному монастырю Вардзия, а затем продолжим наш путь к замку Рабати в Ахалцихе. Ужин в гостевом доме "Ахалцихские байкеры". Ночёвка в городе Ахалцихе.',
      georgiaDay5:
        '160 км (80 км грунтовка). Выезжаем утром к Черному морю через горный перевал Хуло, который является очень интересной и обзорной дорогой. На нашем пути нам встретятся как асфальтовые, так и гравийные дороги. Город назначения - Батуми, ужин на берегу моря. Ночёвка в городе Батуми.',
      georgiaDay6:
        '300 км. Начав с моря, мы направимся в центральную часть страны, по многочисленным горным перевалам и красивым дорогам доберемся до курортного города Боржоми. Ночевка в городе Боржоми.',
      georgiaDay7:
        '250 км. Мы начнем наш день с посещения одного из старейших городов Грузии - Мцхета, который известен как объект всемирного наследия своими культурными памятниками. Следующая остановка - Замка Ананури, после чего мы направимся к горе Казбег, где на высоте 2170м. находится самая известная церковь этого региона - Троицкая церковь Гергети. К концу дня мы доберемся до города Степансминда и остановимся там на ночлег. Ночёвка в городе Степансминда.',
      georgiaDay8:
        '130 км. Цель на 8-й день - почувствовать колорит столицы, Тбилиси. Поэтому, приехав туда мы припоркуем мотоциклы и продолжим исследовать город на машине и пешком. Мы посетим главные достопримечательности города, прогуляемся по старому городу Тбилиси, вечером ужин в традиционном грузинском ресторане с народной музыкой.',
      georgiaDay9:
        '210 км. Пересекая границу обратно в Армению, по дороге в ущелье доберемся до Лорийского региона и остановимся у монастырского комплекса 10 века Ахпат, далее в город Санаин, где расположен средневековый образовательно-церковный комплекс Санаин. В этом же городе мы посетим музей братьев Микоян. Затем по красивой дороге в горных долинах мы делаем красивую петлю и добираемся до деревни Дзорагет. Ночевка в отеле-замке около Дзорагет.',
      georgiaDay10:
        '200 км (75 км гравий). На 10-й день после приезда в город Иджеван мы поднимемся в горы по грунтовым дорогам до города Кармир. После того как вернемся на асфальтовую дорогу, мы двинемся к озеру Парз по маленькой лесной дороге. Ночевка в городе Дилижан.',
      georgiaDay11:
        '225 км. Едем в село молокан, Семеновка. Далее остановка на живописном полуострове озера Севан. Пробуем рыбу из озера в ресторане на берегу. Осмотр средневекового кладбища в селе Норадус. Через селимский перевал едем в город Ехегнадзор. По дороге короткая остановка у караван-сарая на шелковом пути. Ночевка в курортном городе Джермук.',
      georgiaDay12:
        '150 км. Едем к Джермукскому водопаду. Далее: пещерное село Хндзореск. По дороге остановимся в месте, известном как Караундж (армянский Стоунхендж). Ночевка в городе Горис.',
      georgiaDay13:
        '130 км (80 км грунтовка). По трассе едем до развилки с дорогой недалеко от села Шурнух. Поворачиваем на грунтовую дорогу с многочисленными серпантинами, которая пролегает через живописные горы. Выехав к селу Шинуайр, держим направление на Татевский монастырь. По желанию можно прокатиться по самой длинной в Европе канатной дороге туда и обратно. После осмотра монастыря едем в сторону города Капан через горные села. Ночёвка в городе Капан.',
      georgiaDay14:
        '240 км. Выезжаем из Капана на юг через заповедник Шикахог в сторону границы с Ираном. Делая большую петлю по красивой горной дороге (160 км), возвращаемся в Капан, а затем в Горис. Ночёвка в городе Горис.',
      georgiaDay15:
        '270 км. Возвращаемся в Ереван. На 170-ом километре сворачиваем на живописную дорогу, пролегающую по дну каньона к церковному комплексу Нораванк. Вечером в Ереване устраивается прощальный ужин.',
      georgiaDay16: 'Конец путешествия, проводы в аэропорту.',

      georgiaDesc: '15-дневный тур, 14 дней езды.',
      georgiaNote: 'Наличие автомобиля технической поддержки обязательно',
      shortTour: 'Короткий',

      shortDay1:
        'Прибытие в Ереван, размещение в гостинице, экскурсия по городу и ознакомление с мотоциклами в гараже.',
      shortDay2:
        '260 км. Посещение монастыря Хор Вирап, ущелья и монастыря Нораванк, затем путь к Татевскому монастырю. Ночевка в Горисе.',
      shortDay3:
        '300 км. Обмерватория Караундж, Селимский перевал, озеро Севан и монастырь, побережье озера. Ночевка в Енокаване.',
      shortDay4:
        '240 км. Озеро Парзлич, озеро Кари Лич, каменный алфавит, замок Амберд, монастырь Сагмосаванк. Прощальный ужин и ночевка в Ереване.',
      shortDay5: 'Конец путешествия, проводы в аэропорту.',

      apostlesDay1:
        'Прибытие в столицу Армении - город Ереван. Размещение в гостинице. Экскурсия по городу. Ознакомление с мотоциклами в гараже.',
      apostlesDay2:
        '150 км. Трансфер в гараж в 8:00. Держим направление в сторону монастыря Гегард. Остановки – Античный храм в Гарни, монастырь Гегард. Далее подъем на гору Арагац. Остановки – крепость Амберд, привал у Езидских кочевых пастухов, озеро Кари лич, ночёвка в отеле на горе Арагац.',
      apostlesDay3:
        '180 км (грунтовка 70 км). Едем на север к водопаду “Трчкан” у села Ширакамут, далее по грунтовой дороге в город Степанаван. Осмотр крепости “Лори берд”. Ночевка в Степанаване.',
      apostlesDay4:
        '160 км. Едем в село Одзун. Посещение музея братьев Микоян. Спуск в ущелье реки Дебед. Далее в монастырь Ахпат. Ночевка в городе Иджеван.',
      apostlesDay5:
        '150 км (грунтовка 75 км). По грунтовой дороге едем в сторону села Навур. Затем к озеру Парз лич. Короткий перерыв в кафе рядом с озером. Останавливаемся в аутентичном отеле в городе Дилижан.',
      apostlesDay6:
        '225 км. Едем в село молокан, Семеновка. Далее остановка на живописном полуострове озера Севан. Пробуем рыбу из озера в ресторане на берегу. Осмотр средневекового кладбища в селе Норадус. Через селимский перевал едем в город Ехегнадзор. По дороге короткая остановка у караван-сарая на шелковом пути. Ночевка в курортном городе Джермук.',
      apostlesDay7:
        '150 км. (40км грунтовка) По дороге М2 едем к водопаду Шаки недалеко от города Сисиан. Далее следуем в пещерное село Хндзореск. Осмотр старого села. Затем едем в место, известное как Караундж ‘’армянский стоунхендж”. Ночевка в городе Горис.',
      apostlesDay8:
        '130 км (грунтовка 80км). По трассе едем до развилки с дорогой недалеко от села Шурнух. Поворачиваем на грунтовую дорогу с многочисленными серпантинами которая пролегает через живописные горы. Выехав к селу Шинуайр, держим направление на Татевский монастырь. По желанию можно прокатиться по самой длинной в Европе канатной дороге туда и обратно. После осмотра монастыря едем в сторону города Капан через горные села. Ночёвка в городе Капан.',
      apostlesDay9:
        '240 км. Выезжаем из города Капан на юг через Шикаохский заповедник в сторону границы с Ираном. Сделав большую петлю по красивой горной дороге (160км) возвращаемся в Капан и дальше едем в город Горис. Ночёвка в городе Горис.',
      apostlesDay10:
        '270 км. Возвращаемся в Ереван. На 170-ом километре сворачиваем на живописную дорогу, пролегающему по дну каньона к церковному комплексу Нораванк. Вечером в Ереване устраивается прощальный ужин.',
      apostlesDay11: 'Конец путешествия, проводы в аэропорту.',

      northDay1:
        'Прибытие в столицу Армении - город Ереван. Размещение в гостинице. Экскурсия по городу. Ознакомление с мотоциклами в гараже.',
      northDay2:
        '150 км. Трансфер в гараж в 8:00. Держим направление в сторону монастыря Гегард. Остановки – Античный храм в Гарни, монастырь Гегард. Далее подъем на гору Арагац. Остановки – крепость Амберд, привал у Езидских кочевых пастухов, озеро Кари лич, ночёвка в отеле на горе Арагац.',
      northDay3:
        '180 км (грунтовка 70 км). Едем на север к водопаду “Трчкан” у села Ширакамут, далее по грунтовой дороге в город Степанаван. Осмотр крепости “Лори берд”. Ночевка в Степанаване.',
      northDay4:
        '160 км. Едем в село Одзун. Посещение музея братьев Микоян. Спуск в ущелье реки Дебед. Далее в монастырь Ахпат. Ночевка в городе Иджеван.',
      northDay5:
        '150 км (грунтовка 75 км). По грунтовой дороге едем в сторону села Навур. Затем к озеру Парз лич. Короткий перерыв в кафе рядом с озером. Останавливаемся в аутентичном отеле в городе Дилижан.',
      northDay6:
        '290 км (асфальт). Едем в село молокан Семеновка. Далее остановка на живописном полуострове озера Севан. Пробуем рыбу из озера в ресторане на берегу. Огибаем озеро Севан. Осмотр средневекового кладбища в селе Норадус. Возвращаемся в Ереван. Вечером в Ереване устраивается прощальный ужин.',
      northDay7: 'Конец путешествия, проводы в аэропорту.',

      southDay1:
        'Прибытие в столицу Армении город Ереван. Размещение в гостинице. Экскурсия по городу. Ознакомление с мотоциклами в гараже.',
      southDay2:
        '230 км. Прибытие в гараж в 8:00. Держим путь в курортный город Джермук. Остановки – Озеро Севан, средневековое кладбище в селе Норадус, караван-сарай на шелковом пути. Ночевка в курортном городе Джермук.',
      southDay3:
        '130 км (грунтовка 80 км). По трассе едем до развилки с дорогой недалеко от села Шурнух. Поворачиваем на грунтовую дорогу с многочисленными серпантинами которая пролегает через живописные горы. Выехав к селу Шинуайр, держим направление на Татевский монастырь. По желанию можно прокатиться по самой длинной в Европе канатной дороге туда и обратно. После осмотра монастыря едем в сторону города Капан через горные села. Ночёвка в городе Капан.',
      southDay4:
        '240 км. Выезжаем из города Капан на юг через Шикаохский заповедник в сторону границы с Ираном. Сделав большую петлю по красивой горной дороге (160 км) возвращаемся в Капан и дальше едем в город Горис. Остановки – аллея платанов, город Мегри. Ночевка в Горисе.',
      southDay5:
        '180 км (грунтовка 60 км). Выезжаем из города Горис едем в место, известное как Караундж ‘’армянский стоунхендж”. Затем осматриваем пещерное село Хндзореск, расположенное на крутых склонах ущелья. Едем в город Ехегнадзор через Гергерский заповедник по грунтовке. Останавливаемся у пасечников пробовать мед. Ночевка в Ехегнадзоре.',
      southDay6:
        '190 км. Едем в сторону Еревана. По пути сворачиваем на живописную дорогу, пролегающему по дну каньона к церковному комплексу Нораванк. Далее останавливаемся у церкви Хор Вирап у подножия горы Арарат. Затем следуем в деревню Гарни осмотреть античный храм и находящийся недалеко уникальный монастырь Гегард. Возвращаемся в Ереван. Вечером в городе устраивается прощальный ужин.',
      southDay7: 'Конец путешествия, проводы в аэропорту.',

      weight: 'Масса',
      type: 'Тип',
      engine: 'Двигатель',
      stroke: 'Такт',
      volume: 'Объём',
      finalDrive: 'Главная передача',
      transmission: 'КПП',
      power: 'Мощность',
      cooling: 'Охлаждение',

      bmw1weight: '244 кг',
      dualType: 'Тур-эндуро',
      bmw1engine: '2 Цилиндр',
      cardan: 'Кардан',
      speed5: '5 ступеней',
      airLiquid: 'Воздушно-масляное',
      bmw2weight: '191 кг',
      engineCylinder1: '1 Цилиндр',
      chain: 'Цепь',
      suzukiWeight: '166 кг',
      airOil: 'Воздушно-масляное',
      hondaWeight: '205 кг',
      vTwinEngine: '2 Цилиндр',
      liquid: 'Жидкостное',
      kawasakiWeight: '192 кг',
      kawasaki2Weight: '189 кг',
      yamahaWeight: '181 кг',
      hp: 'л.с.',

      terms: 'Условия',
      term1: '- 24-часовая аренда, включая налоги.',
      term2: '- Максимальный пробег 250 км/день.',
      term3: '- Замок безопасности',
      term4: '- Страхование мотоциклов',
      term5: '- Дополнительно: доступны услуги гида.',

      requirement1: '- Должен быть 21 год или старше, паспорт',
      requirement2: '- Водительское удостоверение мотоциклиста.',
      requirement3: '- Страховой залог наличными в размере 300€.',
      requirement4: '- Страховка пассажира 25€.',

      price1: '- 1-6 дней: 80€ в день (начиная с)',
      price2: '- 7-14 дней: 70€ в день',
      price3: '- 15+ дней: 60€ в день',
      price4: '- В случае пассажира: +25€ в день.',
      price5: '- Автомобиль технической поддержки: 150€ в день',
      price6: '- Залог за ущерб: 300€',
      clickToCome: 'Дорога к нам',
      footerTitle: 'ИНДИВИДУАЛЬНЫЙ ТУР',
      footerText1:
        'Специально для тех, у кого есть желание, но мало времени, мы предлагаем возможность заказного тура: вы самостоятельно можете выбрать маршрут и длительность вашей поездки. ',
      footerText2:
        'Туры пройдут на мотоциклах двойного назначения в сопровождении опытного гида на мотоцикле и машины технической поддержки.',
      footerText3: 'Сезон проведения мототуров – с 15 апреля по 15 октября.',
      navigation: 'Руководство ',
      contacts: 'Связаться с нами',
      social: 'Социальные платформы',
      address: 'Армения Ереван Давташен, 3-й квартал 19/2 дом',
      tour66Text:
        'Если вы хотите незабываемый тур по бездорожью для себя и своих друзей — это то, что вам нужно. Наш грузовик может доставить до 13 пассажиров в самые красивые и укромные места Армении. Для небольших групп или больших расстояний мы также предоставляем автомобили 4х4 с профессиональными водителями/гидами.',
    },
  },
  am: {
    translation: {
      dreamRiders: 'ԴՐԻՄՐԱՅԴԵՐՍ',
      slideLabel: 'ՄՈՏՈՏՈՒՐԵՐ ԵՎ ՎԱՐՁԱԿԱԼՈՒԹՅՈՒՆ ՀԱՅԱՍՏԱՆՈՒՄ ԻՆՉՊԵՍ ՆԱԵՒ ՎՐԱՍՏԱՆՈՒՄ.',
      about:
        'Դրիմրայդերս-ը ընկերություն է, որը հիմնադրվել է դեռևս 2012 թվականին ճանապարհորդների կողմից ճանապարհորդների համար: Հայաստանի ճանապարհները նույնպես շատ են օգնում մեր նպատակին: Օձաձև ոլորանների և քարքարոտ ճանապարհների առատությունը հատուկ է մեր երկրի գեղատեսիլ լեռնային լանդշաֆտին: Ինչպես նաև Հայաստանում և Վրաստանում բարձրակարգ մոտոցիկլետային տուրեր կազմակերպելու գործում մեզ շատ է օգնում է մեր սեփական վարորդական փորձը և մեր տարածաշրջանում ճանապարհորդող բազմաթիվ մոտոցիկլիստների արձագանքները:',
      home: 'Գլխավոր',
      tours: 'Մոտո Տուրեր',
      service: 'Սպասարկում և Պահեստավորում',
      serviceLabel: 'Սպասարկում',
      serviceText:
        'Կտրամադրվի պրոֆեսիոնալ մոտեցում, որը ներառում է բոլոր կարևոր տարրերի ստուգումը, ինչպես նաև կտրամադրվի խորհրդատվություն ցանկացած թերության հայտնաբերման և շտկման վերաբերյալ, կանխարգելիչ մոտեցում, ձեր մոտոցիկլետի լավագույն աշխատանքի համար:',
      storage: 'Պահեստավորում',
      storageText:
        'Մեր ընկերությունը տրամադրում է մոտոցիկլետների կայանման ծառայություն։ Ճանապարհորդների և տեղացի մոտոցիկլիստների համար այն դարձել է սիրելի վայր՝ ձմռանը մոտոցիկլետը պահելու, գարնան ընդառաջ նախապես սպասարկելու համար։ Մոտոցիկլետները պահպանվում են տաք և ապահով վայրում՝ 24/7 հսկվող տարացքում:',
      rent: 'Մոտո Վարձակալություն',
      contactUs: 'Կապվել',
      whatOffer: 'Մեր Ծառայությունները',
      tour66: 'Տուր 66',
      aboutUs: 'Մեր մասին',
      gallery: 'Պատկերասրահ',
      more: 'Ավելին',
      apostles: 'Հայաստան. առաքյալների հետքերով',
      north: 'Հյուսիս',
      south: 'Հարավ',
      toursTitle: 'Մոտո Տուրեր',
      apostlesDesc:
        'Երկարությունը՝ 1500 կմ: 10 օր, 9 օր ճանապարհին: Տրամադրվում են ուղեկցորդ՝ մոտոցիկլետով եւ ուղեկցող մեքենա:',
      northDesc:
        'Երկարությունը՝ 900 կմ: 6 օր, 5 օր ճանապարհին: Տրամադրվում են ուղեկցորդ՝ մոտոցիկլետով եւ ուղեկցող մեքենա:',
      southDesc:
        'Երկարությունը՝ 1000 կմ: 6 օր, 5 օր ճանապարհին: Տրամադրվում են ուղեկցորդ՝ մոտոցիկլետով եւ ուղեկցող մեքենա:',
      georgiaTour: 'Հայաստան-Վրաստան',
      shortTour: 'Կարճ',
      day: 'Օր',
      pricePerpass: 'Արժեքը ուղևորի համար',
      escortPrice: 'Ուղեկցող մեքենայի արժեքը',
      priceIncludesAcc:
        'Արժեքն իր մեջ ներառում է կացարան, սնունդ և մոտոցիկլետների ապահովագրություն։',
      price: 'Արժեքը',
      currency: 'եվրո',
      priceIncludes: 'Գինը ներառում է կացարան, սնունդ եւ մոտոցիկլետի ապահովագրություն:',

      apostlesDay1:
        'Ժամանում Հայաստանի մայրաքաղաք Երևան։ Տեղակայում հյուրանոցում: Զբոսանք քաղաքով։ Ծանոթացում մոտոցիկլետների հետ ավտոտնակում։',
      apostlesDay2:
        '(150 կմ) Ժամանում ավտոտնակ 08:00-ին: Առաջին ուղղությունը դեպի Գառնիի հնագույն տաճար, Գեղարդի վանք։ Այնուհետև ձիավարություն դեպի Արագած լեռ: Կանգառներ՝ Ամբերդ ամրոց, կանգառ եզդի քոչվոր անասնապահների մոտ, <<Քարի լիճ>> ։ Գիշերակաց լեռնային հյուրանոցում:',
      apostlesDay3:
        '(180 կմ/70 կմ գրունտային ճանապարհ) Ուղղությունը դեպի հյուսիս՝Շիրակամուտ գյուղ, որտեղ գտնվում է Հայաստանի ամենամեծ ջրվեժ «Թռչկան»-ը։ Այնուհետև գրունտային ճանապարհով դեպի Ստեփանավան։ Այցելություն «Լոռի Բերդ»։ Գիշերակաց Ստեփանավանում։',
      apostlesDay4:
        '(160 կմ) Ուղղությունը դեպի Օձուն գյուղ։ Կարճ այց Միկոյան եղբայրների թանգարան։ Այնուհետև Դեբեդ գետի կիրճով դեպի Հաղպատի վանք։ Գիշերակաց Իջևանում։',
      apostlesDay5:
        '(150 կմ/75 կմ գրունտային ճանապարհ) Հողային ճանապարհով դեպի Նավուր գյուղ: Այնուհետև դեպի Պարզ լիճ։ Կարճ ընդմիջում լճի մոտ գտնվող սրճարանում: Գիշերակաց Դիլիջանում։',
      apostlesDay6:
        '(225 կմ) Ուղղությունը դեպի մոլոկանների գյուղ Սեմյոնովկա։ Հաջորդ կանգառը՝ Սևանա լճի գեղատեսիլ թերակղզում։ Լճի ձկան փորձարկում լողափի ռեստորանում: Այցելություն Նորադուս գյուղի միջնադարյան գերեզմանատուն: Այնուհետև՝ Սելիմի լեռնանցքով դեպի Եղեգնաձոր։ Ճանապարհին արագ կանգառ Մետաքսի ճանապարհ քարավանատան մոտ։ Գիշերակաց առողջարանային Ջերմուկ քաղաքում։',
      apostlesDay7:
        '( 150 կմ/ 40 կմ գրունտային ճանապարհ) Ուղղությունը՝ մայրուղով դեպի Շաքիի ջրվեժ։ Այնուհետև այցելություն Խնձորեսկ հին բնակավայրը և «Քարահունջ» (հայկական Սթոունհենջ): Գիշերակաց Գորիսում:',
      apostlesDay8:
        '(130 կմ/80կմ գրունտային ճանապարհ) Մայրուղով դեպի Շուռնուխ գյուղի մոտ գտնվող ճամփաբաժան,որտեղից գրունտային ճանապարհով,բազմաթիվ ոլորաններով, ուղղություն ենք վերցնում դեպի գեղատեսիլ լեռներ: Այնուհետև Շինուայր գյուղից ուղևորվում ենք դեպի Տաթևի վանք։ Ցանկության դեպքում հնարավոր է նաև օգտվել Եվրոպայի ամենաերկար ճոպանուղուց։ Վանք այցելությունից հետո, ուղղությունը դեպի Կապան քաղաք՝ լեռնային գյուղերով։ Գիշերակաց Կապանում։',
      apostlesDay9:
        '(240 կմ) Ուղղությունը հարավ՝ Շիկահող արգելոցով Իրանի հետ սահման։ Գեղեցիկ լեռնային ճանապարհով (160 կմ) մեծ պտույտ դեպի Կապան։',
      apostlesDay10:
        '(270 կմ) Վերադարձ Երևան։ 170-րդ կիլոմետրին շրջադարձ դեպի ձորի հատակով անցնող գեղատեսիլ ճանապարհ, որը տանում է Նորավանք եկեղեցական համալիր։ Երեկոյան՝ հրաժեշտի ընթրիք Երևանում։',
      apostlesDay11: 'Ճամփորդության ավարտ։ Ուղեկցում դեպի օդանավակայան։',

      northDay1:
        'Ժամանում Հայաստանի մայրաքաղաք Երևան։ Տեղակայում հյուրանոցում: Զբոսանք քաղաքով։ Ծանոթացում մոտոցիկլետների հետ ավտոտնակում։',
      northDay2:
        '(230 կմ) Ժամանում ավտոտնակ 08:00-ին: Ուղղությունը դեպի առողջարանային քաղաք Ջերմուկ: Կանգառներ՝ Սևանա լիճ, այցելություն Նորադուս գյուղի միջնադարյան գերեզմանատուն, կանգառ Մետաքսի ճանապարհ քարավանատան մոտ։ Գիշերակաց Ջերմուկ քաղաքում։',
      northDay3:
        '(130 կմ/80կմ գրունտային ճանապարհ) Մայրուղով դեպի Շուռնուխ գյուղի մոտ գտնվող ճամփաբաժան, որտեղից գրունտային ճանապարհով,բազմաթիվ ոլորաններով, ուղղություն ենք վերցնում դեպի գեղատեսիլ լեռներ: Այնուհետև Շինուայր գյուղից ուղևորվում ենք դեպի Տաթևի վանք։ Ցանկության դեպքում հնարավոր է նաև օգտվել Եվրոպայի ամենաերկար ճոպանուղուց։ Վանք այցելությունից հետո, ուղղությունը դեպի Կապան քաղաք՝ լեռնային գյուղերով։ Գիշերակաց Կապանում։',
      northDay4:
        '(240 կմ) Ուղղությունը հարավ՝ Շիկահող արգելոցով Իրանի հետ սահման։ Գեղեցիկ լեռնային ճանապարհով (160 կմ) մեծ պտույտ դեպի Կապան։ Գիշերակաց Գորիում։',
      northDay5:
        '(180 կմ/60 գրունտային ճանապարհ) Գորիսից ուղղությունը դեպի Քարահուն՝ հայկական Սթոունհենջ։ Այնուհետև այցելություն Խնձորեսկի լքված հին քարանձավային բնակավայր։ Հերհերի արգելոցի միջով, գրունտային ճանապարհով, դեպի Եղեգնաձոր։ Գիշերակաց Եղեգնաձորում։',
      northDay6:
        '(200 կմ) Ուղղությունը դեպի Երևան։ 170-րդ կիլոմետրին շրջադարձ դեպի ձորի հատակով անցնող գեղատեսիլ ճանապարհ, որը տանում է Նորավանք եկեղեցական համալիր։ Հաջորդ կանգառը՝ Արարատ լեռան ստորոտին գտնվող Խոր Վիրապ եկեղեցում։ Այնուհետև դեպի Գառնի գյուղ՝ տեսնելու հնագույն տաճարը,ինչպես նաև հարևանությամբ գտնվող եզակի Գեղարդ վանքը։ Երեկոյան հրաժեշտի ընթրիք Երևանում։',
      northDay7: 'Ճամփորդության ավարտ։ Ուղեկցում դեպի օդանավակայան։',

      southDay1:
        'Ժամանում Հայաստանի մայրաքաղաք Երևան։ Տեղակայում հյուրանոցում: Զբոսանք քաղաքով։ Ծանոթացում մոտոցիկլետների հետ ավտոտնակում։',
      southDay2:
        '(150 կմ) Ժամանում ավտոտնակ 08:00-ին: Առաջին ուղղությունը դեպի Գառնու հնագույն տաճար, Գեղարդի վանք։ Այնուհետև ձիավարություն դեպի Արագած լեռ: Կանգառներ՝ Ամբերդ ամրոց, կանգառ եզդի քոչվոր անասնապահների մոտ, Քարի լիճ ։ Գիշերակաց լեռնային հյուրանոցում:',
      southDay3:
        '(180 կմ/70 կմ գրունտային ճանապարհ) Ուղղությունը դեպի հյուսիս՝ Շիրակամուտ գյուղ, որտեղ գտնվում է Հայաստանի ամենամեծ ջրվեժ Թռչկան-ը։ Այնուհետև գրունտային ճանապարհով դեպի Ստեփանավան։ Այցելություն Լոռի Բեր։ Գիշերակաց Ստեփանավանում։',
      southDay4:
        '(160 կմ) Ուղղությունը դեպի Օձուն գյուղ։ Կարճ այց Միկոյան եղբայրների թանգարան։ Այնուհետև Դեբեդ գետի կիրճով դեպի Հաղպատի վանք։ Գիշերակաց Իջևանում։',
      southDay5:
        '(150 կմ/75 կմ գրունտային ճանապարհ) Գրունտային ճանապարհով դեպի Նավուր գյուղ: Այնուհետև դեպի Պարզ լիճ։ Կարճ ընդմիջում լճի մոտ գտնվող սրճարանում: Գիշերակաց Դիլիջանում։',
      southDay6:
        '(225 կմ) Ուղղությունը դեպի մոլոկանների գյուղ <<Սեմյոնովկա>>։ Հաջորդ կանգառը՝ Սևանա լճի գեղատեսիլ թերակղզում։ Լճի ձկան փորձարկում լողափի ռեստորանում: Այցելություն Նորադուս գյուղի միջնադարյան գերեզմանատուն: Վերադարձ Երևան։',
      southDay7: 'Օր 7. Ճամփորդության ավարտ։ Ուղեկցում դեպի օդանավակայան։',

      georgia: 'Հայաստան – Վրաստան տուր',
      note: 'Նշում',
      georgiaNote: 'Ուղեկցող մեքենան պարտադիր է',
      georgiaDesc:
        '15 օր, 14 օր ճանապարհին: Տրամադրվում են ուղեկցորդ՝ մոտոցիկլետով և տեխնիկական աջակցության մեքենա:',

      georgiaDay1:
        'Օր 1. Ժամանում Հայաստանի մայրաքաղաք Երևան։ Տեղակայում հյուրանոցում: Զբոսանք քաղաքով։ Ծանոթացում մոտոցիկլետների հետ ավտոտնակում։',
      georgiaDay2:
        '(150 կմ) Ժամանում ավտոտնակ 08:00-ին: Առաջին ուղղությունը դեպի Գառնիի հնագույն տաճար, Գեղարդի վանք։ Այնուհետև ձիավարություն դեպի Արագած լեռ: Կանգառներ՝ Ամբերդ ամրոց, կանգառ եզդի քոչվոր անասնապահների մոտ, Քարի լիճ։ Գիշերակաց լեռնային հյուրանոցում:',
      georgiaDay3:
        '(170 կմ/40կմ գրունտային ճանապարհ) Ուղղությունը դեպի Սպիտակի լեռնանցք, ճանապարհին կանգառ Քարե այբուբեն-ի մոտ, այնուհետև դեպի Սաղմոսավանք, որը գտնվում է Հայաստանի ամենագեղեցիկ կիրճի հարևանությամբ։ Գիշերակաց Գյումրի քաղաքում, որը հայտնի է որպես արհեստների գլխավոր քաղաք։',
      georgiaDay4:
        '(225 կմ) Ուղղությունը դեպի Վրաստան: Սահմանը հատելուց հետո կանցնենք Ախալքալաք քաղաքով և կմտնենք կիրճը, որը մեզ կտանի դեպի Վարձիա քարանձավային քաղաք։ Այնուհետև դեպի Ախալցխայի Ռաբաթի ամրոց: Գիշերակաց Ախալցխայի բայքերների հյուրատանը:',
      georgiaDay5:
        '(160կմ/80կմ գրունտային ճանապարհ) Առավոտյան Խուլոյի լեռնանցքով մեկնում դեպի Սև ծով։ Ճանապարհին կհանդիպենք ինչպես ասֆալտապատ, այնպես էլ խճաքարով ճանապարհներ։ Նպատակակետը՝ Բաթում քաղաք, ընթրիք ծովի ափին։',
      georgiaDay6:
        '(300 կմ) Սև ծովից սկսած ուղղությունը դեպի երկրի կենտրոնական հատված: Բազմաթիվ լեռնանցքներով և գեղեցիկ ճանապարհներով կհասնենք Բորժոմի առողջարանային քաղաք: Գիշերակաց Բորժոմիում:',
      georgiaDay7:
        '(250 կմ) Օրը սկսվում է Վրաստանի հնագույն քաղաքներից մեկի՝ Մցխեթա-ի այցելությամբ, որը իր մշակութային հուշարձաններով հայտնի է որպես համաշխարհային ժառանգության վայր։ Հաջորդ կանգառը Անանուրի ամրոցի համալիրն է։ Այնուհետև ուղղությունը դեպի Կազբեգի լեռ, որտեղ 2170 մ բարձրության վրա գտնվում է այդ տարածքի ամենահայտնի Գերգեթի Երրորդություն եկեղեցին։ Օրվա ավարտին կհասնենք Ստեփանզմինդա:',
      georgiaDay8:
        '(130 կմ) 8-րդ օրվա նպատակն է զգալ մայրաքաղաք Թբիլիսիի գրավչությունը: Այդ իսկ պատճառով, տեղում լինելուն պես, կկայանենք մոտոցիկլետները և կուսումնասիրենք քաղաքը մեքենայով և ոտքով: Կայցելենք քաղաքի գեղատեսիլ վայրերը, կզբոսնենք Թբիլիսիի հին քաղաքում, երեկոյան կընթրենք վրացական ավանդական ռեստորանում՝ ժողովրդական երաժշտությամբ:',
      georgiaDay9:
        '(210 կմ) Անցնելով սահմանը դեպի Հայաստան, ոկուղեվորվենք դեպի Լոռու մարզ։ Առաջին կանգառը 10-րդ դարի Հաղպատ վանական համալիրում, որից հետո դեպի Սանահին քաղաք, որտեղ գտնվում է միջնադարյան կրթական և եկեղեցական համալիր Սանահինը: Նույն քաղաքում այց Միկոյան եղբայրների թանգարան։ Այնուհետև լեռնային հովիտներով անցնող գեղեցիկ ճանապարհով դեպի Ձորագետ:',
      georgiaDay10:
        '(200 կմ/75 կմ գրունտային ճանապարհ) 10-րդ օրը հասնելով Իջևան քաղաք կուղեվորվենք լեռներ, գրունտային ճանապարհներով դեպի Կարմիր քաղաք։ Ասֆալտ վերադառնալուց հետո, կիրճի գեղատեսիլ ճանապարհով, դեպի Պարզ լիճ։ Գիշերակաց Դիլիջան քաղաքում:',
      georgiaDay11:
        '(175 կմ) Ողղությունը դեպի Սեմենյովկա, մոլոկանների գյուղ։ Հաջորդ կանգառը՝ Սևանա լճի գեղատեսիլ թերակղզում։ Ձկան համտեսում լճին կից ռեստորանում: Այցելություն Նորատուս գյուղի միջնադարյան գերեզմանատուն: Այնուհետև Սելիմի լեռնանցքով դեպի Եղեգնաձոր։ Ճանապարհին արագ կանգառ Մետաքսի ճանապարհի քարավանատան մոտ: Գիշերակաց Ջերմուկում:',
      georgiaDay12:
        '(150 կմ) Այցելություն Ջերմուկի ջրվեժ, այնուհետև այց Խնձորեսկ հին բնակավայրը։ Ճանապարհին այցելություն Քարահունջ, հայկական Սթոունհենջ պատմամշակութային արգելոց։ Գիշերակաց Գորիսում:',
      georgiaDay13:
        '(130 կմ/80կմ գրունտային ճանապարհ) Մայրուղով դեպի Շուռնուխ գյուղի մոտ գտնվող ճամփաբաժան, որտեղից գրունտային ճանապարհով, բազմաթիվ ոլորաններով, ուղղություն ենք վերցնում դեպի գեղատեսիլ լեռներ: Այնուհետև Շինուայր գյուղից ուղևորվում ենք դեպի Տաթևի վանք։ Ցանկության դեպքում հնարավոր է նաև օգտվել Եվրոպայի ամենաերկար ճոպանուղուց։ Վանք այցելությունից հետո, ուղղությունը դեպի Կապան քաղաք՝ լեռնային գյուղերով։ Գիշերակաց Կապանում:',
      georgiaDay14:
        '(240 կմ) Կապանից, Շիկահող արգելոցով, շարժվում ենք դեպի հարավ՝ հասնելով դեպի Իրանի սահման։ Գեղեցիկ լեռնային ճանապարհով (160 կմ),մեծ պտույտ կատարելով, վերադառնում ենք դեպի Կապան, այնուհետև Գորիս։',
      georgiaDay15:
        '(270 կմ) Ուղղությունը դեպի Երևան։ 170-րդ կիլոմետրին շրջադարձ դեպի ձորի հատակով անցնող գեղատեսիլ ճանապարհ, որը տանում է Նորավանք եկեղեցական համալիր։ Երեկոյան հրաժեշտի ընթրիք Երևանում։',
      georgiaDay16: 'Շրջայցի ավարտ, տեղափոխում օդանավակայան:',

      short: '«Կարճ» տուր',
      shortDesc:
        'Երկարությունը՝ 900 կմ։ 4 օր, որից 3 օրը՝ ճանապարհին։ Տրամադրվում են ուղեկցորդ՝ մոտոցիկլետով եւ տեխօգնության մեքենա: Գինը ներառում է կացարան, սնունդ և մոտոցիկլետի ապահովագրություն։',

      shortDay1:
        'Ժամանում Հայաստանի մայրաքաղաք Երևան։ Տեղակայում հյուրանոցում: Զբոսանք քաղաքով։ Ծանոթացում մոտոցիկլետների հետ ավտոտնակում։(260 կմ) Ժամանում ավտոտնակ 08:00-ին: Ուղղությունը դեպի Խոր Վիրապ վանք։ Կարճ կանգառից հետո, ձորի հատակով անցնող գեղատեսիլ ճանապարհով, դեպի Նորավանք եկեղեցական համալիր: Այնուհետև դեպի Տաթևի վանք, որտեղ գտնվում է տարածաշրջանի ամենաերկար ճոպանուղին։ Գիշերակաց Գորիս քաղաքում։',
      shortDay2:
        'Ժամանում ավտոտնակ 8:00-ին: Առաջին ուղղությունը դեպի Խոր Վիրապ վանք: Կարճ կանգառից հետո,ձորի հատակով անցնող գեղատեսիլ ճանապարհով, կհասնենք Նորավանք եկեղեցական համալիր: Այնուհետև կշարժվենք դեպի Տաթևի վանք, որտեղ գտնվում է Եվրոպայի ամենաերկար ճոպանուղին: Գիշերակաց Գորիս քաղաքում։',
      shortDay3:
        '(300 կմ) Ուղղությունը դեպի «Քարահունջ» Սթոունհենջ պատմամշակութային արգելոց։ Այնուհետև՝ Մետաքսի ճանապարհով դեպի Քարվանսարան։ Պտույտ Սևանա լճի շուրջ, այց Սևանավանք եկեղեցի։ Օրվա ավարտին գեղեցիկ ճանապարհով դեպի Ենոքավան գյուղ։ Գիշերակաց Ենոքավանում։',
      shortDay4:
        '(240 կմ) Ուղղությունը Դիլիջանի լեռներում գտնվող Պարզ լիճ, կարճ կանգառ լճի մոտակայքում գտնվող սրճարանում։ Հաջորդ կանգառը Քարի լիճ, որը գտնվում է Հայաստանի ամենաբարձր լեռան՝ Արագածի վրա։ Ճանապարհին կարճ կանգառ «Քարե այբուբենի» մոտ: Հաջորդ ուղղությունը դեպի 7-րդ դարի ամրոց Ամբերդ։ Այնուհետև գեղեցիկ ճանապարհով դեպի 13-րդ դարի վանական համալիր Սաղմոսավանք։ Երեկոյան հրաժեշտի ընթրիք Երևանում:',
      shortDay5: 'Ճամփորդության ավարտ։ Ուղեկցում դեպի օդանավակայան։',

      weight: 'Քաշը',
      type: 'Տիպ',
      engine: 'Մխոցների քանակ',
      stroke: 'Տակտ',
      volume: 'Աշխատանքային ծավալ',
      finalDrive: 'Փոխանցման տեսակ',
      transmission: 'Փոխանցման տուփը',
      power: 'Հզորություն',
      cooling: 'Հովացում',

      bmw1weight: '244 կգ',
      dualType: 'Երկակի նշանակության',
      bmw1engine: '2',
      cardan: 'Լիսեռ',
      speed5: '5 աստիճան',
      airLiquid: 'Յուղ',
      bmw2weight: '191 կգ',
      engineCylinder1: '1',
      chain: 'Շղթա',
      suzukiWeight: '166 կգ',
      airOil: 'Յուղ',
      hondaWeight: '205 կգ',
      vTwinEngine: '2',
      liquid: 'Հեղուկ',
      kawasakiWeight: '192 կգ',
      kawasaki2Weight: '189 կգ',
      yamahaWeight: '181 կգ',
      hp: 'ձիաուժ',

      terms: 'Պայմաններ',
      term1: '- Շուրջօրյա վարձավճար՝ ներառյալ հարկերը',
      term2: '- Առավելագույն վազքը 250 կմ/օր',
      term3: '- Անվտանգության կողպեք',
      term4: '- Մոտոցիկլետների ապահովագրություն',
      term5: '-Ըստ ցանկության ուղեկցող ծառայություն',

      requirement1: '- Պետք է լինի 21 տարեկան և ավելի, անձնագիր',
      requirement2: '- մոտոցիկլետի վարորդական իրավունք',
      requirement3: '- Կանխիկ պահվող վնասի ավանդ 300€',
      requirement4: '- Ուղևորի ապահովագրություն 25€',

      price1: '- 1-6 օր՝ օրական 80€ (սկսած)',
      price2: '- 7-14 օր՝ օրական 70€',
      price3: '- 15+ օր՝ օրական 60€',
      price4: '- Ուղևորի դեպքում՝ օրական +25€',
      price5: '- Ուղեկցող մեքենա՝ օրական 150€',
      price6: '- Վնասի չափը՝ 300€',
      clickToCome: 'Դեպի Մեզ',
      footerTitle: 'ՊԱՏՎԵՐՈՎ ՏՈՒՐ',
      footerText1:
        'Նրանց համար, ովքեր ունեն շրջագայելու ցանկություն, սակայն կարճ ժամանակ, մենք առաջարկում ենք «հատուկ տուրերի» տարբերակը, որտեղ կարող եք ընտրել ուղեւորության երթուղին եւ ձեզ հարմար տեւողությունը:',
      footerText2:
        'Մոտոտուրերը կիրականացվեն երկակի նշանակության մոտոցիկլետներով՝ փորձառու ուղեկցորդի եւ տեխսպասարկման մեքենայի ուղեկցությամբ: ',
      footerText3:
        'Մոտոտուրերի կազմակերպման ժամականահատվածն է՝ ապրիլի 15-ից մինչեւ հոկտեմբերի 15-ը:',
      navigation: 'Ուղղորդում',
      contacts: 'Հետադարձ Կապ',
      social: 'Սոցիալական հարթակներ',
      address: 'Հայաստան Երևան Դավթաշեն, 3-րդ թաղամաս 19/2 շենք',
      tour66Text:
        'Եթե ցանկանում եք անմոռանալի արտաճանապարհային շրջագայություն ձեր և ձեր ընկերների համար, սա այն է, ինչ ձեզ հարկավոր է: Մեր բեռնատարը կարող է տեղափոխել մինչև 13 ուղևոր Հայաստանի ամենագեղեցիկ և թաքնված վայրեր: Ավելի փոքր խմբերի կամ ավելի մեծ հեռավորությունների համար մենք տրամադրում ենք 4x4 տրանսպորտային միջոցներ և պրոֆեսիոնալ վարորդ/ուղեկցորդներ.',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
